<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Restaurant Statistics",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader , Multiselect},
  data() {
    return {
     
      tableData: [],
      foodcourtCardData:[],
      templateArr:[],
      restBranchArr:[],
      restBranchID : "",
      princBranches:[],
      branchID:"",
      cityArr:[],
      city:"",
      countryArr :[],
      country:"",
      dateFilter:[],
      title: "Restaurant Statistics",
      items: [
        {
          text: "View",
        },
        {
          text: "Restaurant Statistics",
          active: true,
        },
      ],
      principleBranchID:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100,"All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key:"restaurant",
          sortable:true,
        },
        {
          key:"Address",
          sortable:true,
        },
        {
          key:"type",
          sortable:true,
        },
        // {
        //   key:"totalOrders",
        //   sortable: true,
        // },
      ],
    };
  },
  created(){
    this.principleBranchID = (this.$storageData.profile.empTypeID==7
        || this.$storageData.profile.empTypeID==8
        || this.$storageData.profile.empTypeID==18
        || this.$storageData.profile.empTypeID==21) ? this.getPrincipleCountry() : this.getStats();
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

},
  
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    if(this.$storageData.profile.principleBranchID==0){
      this.principleBranchID = sessionStorage.getItem('data');
    }else{
      this.principleBranchID = this.$storageData.profile.principleBranchID;
    }
    //  this.readfoodCourtStats();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    // getCorporateBranchByCorpID(){
    //      this.axios
    //     .post(
    //       "getCorporateBranchByCorpID", {corporateID:this.$storageData.profile.corporateID}
    //     )
    //     .then((response) => {
    //     this.branches = response.data.data;
    //     this.branchID = this.branches[0];
    //     this.principleBranchID = this.branchID.corpBranchID;
    //     this.readfoodCourtStats();
          
    //     });
    // },
    onchangeBranch(){
        this.principleBranchID = this.branchID.principleBranchID;
        this.readfoodCourtStats();
    },
    getStats(){
      this.principleBranchID = this.$storageData.profile.principleBranchID;
      this.readfoodCourtStats();
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readfoodCourtStats(){
       var principleID = (this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0) ? this.$storageData.profile.principleID : 0;
       var principleBranchID = this.principleBranchID != ""?this.principleBranchID :
       (this.$storageData.profile.principleBranchID && this.$storageData.profile.principleBranchID != 0) ? this.$storageData.profile.principleBranchID   : 0;

        this.axios
        .post(this.$loggedRole+"/getPrincipleFoodCourtStatistics" ,
          {
          'principleID': principleID,
          'principleBranchID': principleBranchID,
          'transactionType':(this.typeID) ? this.typeID.typeID : 0,
          'dateFilter':this.dateFilter,
          'empTypeID':this.$storageData.profile.empTypeID,
          'empID':this.$storageData.profile.principleAdminID,
          }
        )
        .then((response) => {
          //Then injecting the result to datatable parameters.

           this.tableData = response.data.data;
           this.foodcourtCardData = response.data.cardData;
           //console.log((response));
          
        });
    },
    getPrincipleCountry(){
        this.axios
        .post(this.$loggedRole+"/getPrincipleCountry", {principleID:this.$storageData.profile.principleID}
        )
        .then((response) => {
                this.countryArr = response.data.data;
                // this.country = (this.countryArr) ? this.countryArr[0] : "";
                this.getPrincipleCities();
                
        });
    },
    getPrincipleCities(){
         this.axios
        .post(this.$loggedRole+"/getPrincipleCities", {principleID:this.$storageData.profile.principleID,
            'empTypeID':this.$storageData.profile.empTypeID,
            'empID':this.$storageData.profile.principleAdminID,
          country: (this.country) ? this.country.country : ""}
        )
        .then((response) => {
                this.cityArr = response.data.data;
                // this.city = (this.cityArr) ? this.cityArr[0] : "";
                 this.getPrincipleBranchByPrincipleID();
                
        });
    },

  getPrincipleBranchByPrincipleID(){
         this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID", {principleID:this.$storageData.profile.principleID,
          'empTypeID':this.$storageData.profile.empTypeID,
          'empID':this.$storageData.profile.principleAdminID,
          city: (this.city) ? this.city.city : ""}
        )
        .then((response) => {
        this.princBranches = response.data.data;
        // this.branchID = this.princBranches[0];
        this.principleBranchID = this.branchID.principleBranchID;
        // this.readfoodCourtStats();

        });
    },

    applyFilter(){
      this.principleBranchID = (this.$storageData.profile.empTypeID==7 || this.$storageData.profile.empTypeID==18 || this.$storageData.profile.empTypeID==8 || this.$storageData.profile.empTypeID==21) ?
          this.branchID.principleBranchID : this.$storageData.profile.principleBranchID;
      
      if(this.$storageData.profile.empTypeID==7 || this.$storageData.profile.empTypeID==18 || this.$storageData.profile.empTypeID==8 || this.$storageData.profile.empTypeID==21)
      {
        if(this.principleBranchID===undefined || this.principleBranchID=='' || this.principleBranchID==0  ||
            this.principleBranchID.length==0)
        {
          alert("Please select principle branch");
          return;
        }
      }
      this.readfoodCourtStats();
   
    },
    clearFilter(){
        this.typeID = 0;
        this.country ="";
        this.city = "";
        this.branchID = "";
        this.dateFilter = [];
        this.principleBranchID = (this.$storageData.profile.empTypeID==7 || this.$storageData.profile.empTypeID==18
            || this.$storageData.profile.empTypeID==8 || this.$storageData.profile.empTypeID==21) ? this.branchID.principleBranchID :
            this.$storageData.profile.principleBranchID;
        // this.readfoodCourtStats();
    }



  },
  middleware: "authentication",
};
</script>


<template>
  <Layout>
    <PageHeader  :items="items" />
      
          <!--FILTER START--->
   <div class="card" style="margin-top:-30px">
      <div class="card-body">
          <div class="inner mb-2 row" >
                <!-- Brand List-->
            <div class="col-md-2" style="width:16%"
              > 
                          <label>Select Country </label>
                              <multiselect v-model="country" :options="countryArr" :show-labels="false" label="country"
                                track-by="country" @input="getPrincipleCities();"></multiselect>
          </div>
        <!-- Brand List END-->
        <!-- Branch List-->
               <div class="col-md-2" style="width:16%"
         >   
                     <label>Select City </label>
                        <multiselect v-model="city" :options="cityArr" :show-labels="false" label="city"
                          track-by="city" @input="getPrincipleBranchByPrincipleID();"></multiselect>
         </div>
<!-- Branch List END-->
<!-- Branch List-->
        <div class="col-md-2" style="width:20%" >   
                <label>Select Principal Branch </label>
                <multiselect v-model="branchID" :options="princBranches" :show-labels="false" label="principleBranchName"
                             track-by="principleBranchID"></multiselect>
      </div>
<!-- Branch List END-->
<!-- Date Range-->
<!-- Branch List END-->
 
<!-- Apply filter -->
                <div class="col-md-2"  style="width:auto; margin-top:30px;">   
                 <button class="btn btn-themeOrange"  v-on:click="applyFilter()"> Apply Filter </button>
                </div>
<!-- Apply filter END-->

<!-- Clear filter -->
                <div class="col-md-2"  style="width:16%;margin-top:30px;">   
                 <button class="btn btn-themeBrown"  v-on:click="clearFilter()"> Clear Filter </button>
                </div>
<!-- Clear filter END-->
        </div>
   </div>
 </div>
<!--FILTER END------>
<div class="row" style="margin-top: 30px;">
   
 </div>


    <div class="row">

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
               <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <div class="btn-group" role="group">
                      <button v-if="this.$storageData.login_type == 1" class="btn btn-themeBrown" type="button">Excel</button>
                       <!-- <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                    </div>
               </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                      style="margin-left:5px; margin-right:5px"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      class="form-control form-control-sm ms-2"
                      placeholder="Search..."
                      type="search"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
                <!-- <div class="row">
              
                     <div class="col-sm-6 col-md-3">
                        <label class="form-label" for="formrow-date-input">Date</label>
                          <date-picker v-model="dateFilter" format="DD MMM Y" range append-to-body lang="en" confirm @input="applyFilter();"></date-picker>
                    </div>
                    <div class="col-sm-6 col-md-2 mt-3">
                        <button class="btn btn-themeBrown" style="padding: 4px 8px;margin-top: 16px;" @click="clearFilter();"> Clear Filter </button>
                    </div>
            </div> -->
            <!-- Table -->
            <div class="table-responsive mb-0">
              
              <b-table
                :current-page="currentPage" :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="tableData"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
                hover
                outlined
                responsive
                striped
                @filtered="onFiltered"
              >
              
                 <template v-slot:cell(transactionType)="data">
                     <span v-if="data.item.isExpired==1" style="color:#F3766A;"> Expired </span>
                     <span v-else-if="data.item.transactionType==1" style="color:green;"> Credit </span>
                     <span v-else-if="data.item.transactionType==2" style="color:#A4827D;"> Debit </span>
                     
                </template>

                 <template v-slot:cell(amount)="data">
                     <span v-if="data.item.amount">₹{{data.item.amount}} </span>
                   
                </template>

                
              
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>

.my-class { max-width: 10px !important;}
</style>